
import {useStore} from "vuex"
import {useRoute} from "vue-router"
import router from "@/router"

export default {
	name: "Session",
	setup() {
		const route = useRoute()
		const store = useStore()

		store.dispatch("session/loadSessionById", {
			"sessionId":route.params.session_id
		}).then(() => {
			router.push("/home")
		}).catch((error) => {
			console.log(error)
		})

		return {}
	}
}
